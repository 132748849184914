.k-icon-button.k-button-md {
    min-width: unset;
}

.k-dialog-titlebar {
    border-radius: 0.25rem 0.25rem 0 0 !important;
    padding: 6px 12px;
}

/*.k-dialog-wrapper {*/
/*    z-index: 10001 !important;*/
/*}*/

.k-dialog-wrapper .k-dialog {
    border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
    max-height: 900px;
}

.k-dialog-wrapper .k-dialog > span.k-floating-label-container > label.k-label {
    font-size: 16px;
}

.loading-dialog {
    background-color: rgba(0, 0, 0, 0);
    z-index: 10001;
}

.loading-dialog .k-overlay {
    background-color: transparent;
}

.loading-dialog .k-dialog {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: unset;
    border-radius: 2rem;
    z-index: 10002;
}

.row.patient-picker {
    --bs-gutter-x: 0;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
}

.k-animation-container {
    /*z-index: 10003 !important;*/
}
.col-4.dialog > .k-floating-label-container > .k-input {
    height: 40px;
    font-size: 16px;
}

.col-4.dialog > .k-floating-label-container > .k-label {
    font-size: 16px;
}

.col-4.dialog > .k-floating-label-container > .k-picker {
    height: 40px;
    font-size: 16px;
}

.col-6.dialog > .k-floating-label-container > .k-input {
    height: 40px;
    font-size: 16px;
}

.col-6.dialog > .k-floating-label-container > .k-label {
    font-size: 16px;
}

.col-6.dialog > .k-floating-label-container > .k-picker {
    height: 40px;
    font-size: 16px;
}

.col-8.dialog > .k-floating-label-container > .k-input {
    height: 40px;
    font-size: 16px;
}

.col-8.dialog > .k-floating-label-container > .k-label {
    font-size: 16px;
}

.col-12.dialog > .k-floating-label-container > .k-input {
    height: 40px;
    font-size: 16px;
}

.col-12.dialog > .k-floating-label-container > .k-label {
    font-size: 16px;
}

.col-12.dialog > .k-floating-label-container > .k-picker {
    height: 40px;
    font-size: 16px;
}

.k-button-icon.fas.fa-fax {
    padding-right: 4px;
    font-size: 16px;
}

.inventory-filter-dropdown span.k-input-value-text {
    font-size: 14px;
}

.inventory-filter-dropdown .k-button {
    width: 20px;
}

.pdf-preview-container canvas {
    max-width: 100%;
    height: auto !important;
    border-radius: 0.3rem;
}

.professional-toggle-button .k-button-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notification-appointment-card {
    cursor: pointer;
}

.box-on-hover {
    cursor: pointer;
    transition: all 300ms ease;
}
.box-on-hover:hover {
    background-color: var(--color-ultra-light-primary);
    font-weight: bold;
}

.box.single-select-option-box {
    cursor: pointer;
    padding: 2px 8px;
    width: fit-content;
    transition: all 200ms ease;
}
.box.single-select-option-box:hover {
    font-weight: 500;
    background-color: var(--color-ultra-light-primary);
}
.box.single-select-option-box.selected {
    font-weight: 600;
    background-color: var(--color-ultra-light-primary);
    color: var(--color-darken-primary);
}

.patient-picker-dialog-patient-bar {
    border-radius: 0.3rem;
    transition: all 300ms ease;
}
.patient-picker-dialog-patient-bar-title {
    border-radius: 0.3rem;
    padding: 7px 10px;
    cursor: pointer;
}